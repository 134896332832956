import state from "./moduleTierPackageState.js";
import mutations from "./moduleTierPackageMutations.js";
import actions from "./moduleTierPackageActions.js";
import getters from "./moduleTierPackageGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
