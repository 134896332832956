import axios from "@/axios";
// import axios from "axios";

export default {
  listTierPackage({ commit }, data) {
    return axios.get('/api/v1/admins/tier-packages', { params: data })
  },
  createTierPackage({ commit }, data) {
    return axios.post('/api/v1/admins/tier-packages', data)
  },
  updateTierPackage({ commit }, data) {
    return axios.put(`/api/v1/admins/tier-packages/${data.id}`, data.body)
  },
  getTierPackageDetails({ commit }, id) {
    return axios.get(`/api/v1/admins/tier-packages/${id}`)
  },
  deleteTierPackage({ commit }, id) {
    return axios.delete(`/api/v1/admins/tier-packages/${id}`)
  },
};
